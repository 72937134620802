<script setup>
const error = useError();
</script>
<template>
  <NuxtLayout name="hub">
    <template #content>
      <div
        class="w-full pt-[60px] pb-52 flex flex-col items-center justify-start gap-10 md:gap-6 md:pt-5"
      >
        <h2>This here appears to be an error.</h2>
        <h3>{{ error?.statusMessage ?? error?.message }}</h3>
        <pre
          style="max-width: 80%; white-space: pre-wrap; word-wrap: break-word"
          >{{ error }}</pre
        >
      </div>
    </template>
  </NuxtLayout>
</template>
