export default defineAppConfig({
  ui: {
    primary: 'wb',
    gray: 'wb',
    table: {
      wrapper: 'w-full',
      base: 'border-b border-ht-border-1',
      divide: 'divide-ht-border-2 dark:divide-ht-border-2',
      tbody: 'divide-ht-border-2 dark:divide-ht-border-2',
      th: {
        base: 'text-left rtl:text-right bg-ht-cc-rc-bg-fill dark:bg-ht-cc-rc-bg-fill',
        padding: 'py-2.5',
      },
      td: {
        color:
          'whitespace-normal md:min-w-[80vw] text-ht-text-gray-1 dark:text-ht-text-gray-1',
        padding: 'px-4 py-3',
      },
      tr: {
        base: 'py-2 hover:bg-ht-menu-btn-hover dark:hover:bg-ht-menu-btn-hover',
      },
      emptyState: {
        icon: 'text-black dark:text-white',
      },
      loadingState: {
        icon: 'text-black dark:text-white',
      },
      default: {
        progress: {
          animation: 'carousel',
        },
      },
    },
    button: {
      color: {
        primary: {
          solid:
            'text-white dark:text-black bg-black dark:bg-white border border-black dark:border-white hover:bg-white hover:dark:bg-black hover:text-black hover:dark:text-white ring-0 focus-visible:outline-none transition-all duration-300',
        },
        white: {
          solid:
            'bg-white dark:bg-black text-black dark:text-white hover:bg-black hover:dark:bg-white hover:text-white hover:dark:text-black border border-black dark:border-white ring-0 disabled:opacity-80 transition-all duration-300',
        },
        black: {
          solid:
            'bg-black dark:bg-white text-white dark:text-black hover:bg-white hover:dark:bg-black hover:text-black hover:dark:text-white border border-white dark:border-black ring-0 disabled:opacity-80 transition-all duration-300',
        },
        // gray: {
        //   solid:
        //     'text-ht-btn-gray-label bg-ht-btn-gray border border-ht-gray-hover hover:bg-ht-btn-gray-hover disabled:opacity-80 transition-all duration-300',
        // },
      },
    },
    notification: {
      // @ts-expect-error: Config works, probably a bug in the types
      default: {
        timeout: 2500,
      },
    },
    toggle: {
      active: 'bg-black dark:bg-white',
      inactive: 'bg-black dark:bg-white',
    },
    select: {
      icon: {
        base: 'text-black dark:text-white',
      },
    },
    selectMenu: {
      label: 'text-black dark:text-white',
      select: 'cursor-pointer',
      option: {
        base: 'cursor-pointer',
      },
    },
    skeleton: {
      background: 'bg-ht-btn-gray dark:bg-ht-btn-gray',
    },
  },
});
