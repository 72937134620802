import { useStorage } from '@vueuse/core';
export default defineNuxtRouteMiddleware((to) => {
  if (to.path.startsWith('/client/') && to.params.clientSlug) {
    const state = useStorage(
      'client-paths',
      {} as { [clientSlug: string]: { path: string; expire: Date } }
    );
    state.value[to.params.clientSlug as string] = {
      path: to.path,
      expire: new Date(Date.now() + 1000 * 60 * 5), // 5 minutes
    };

    if (
      /\/client\/[a-z0-9-]*\/project\/[a-z0-9-]*\/[a-z0-9-]*\/.[a-z0-9-/]*/.test(
        to.path
      ) &&
      to.params.projectSlug
    ) {
      const clientProjectSlug = `${to.params.clientSlug}/${to.params.projectSlug}`;
      const pState = useStorage(
        'client-project-stage-paths',
        {} as {
          [clientProjectSlug: string]: {
            [stage: string]: { path: string; expire: Date };
          };
        }
      );
      const stage = to.path.split('/')[5];
      const rest = to.path.split('/').slice(6).join('/');
      if (!pState.value[clientProjectSlug]) {
        pState.value[clientProjectSlug] = {};
      }
      pState.value[clientProjectSlug][stage] = {
        path: rest,
        expire: new Date(Date.now() + 1000 * 60 * 5),
      };
    } else if (
      /\/client\/[a-z0-9-]*\/creative\/[a-z0-9-]*\/[a-z0-9-]*\/.[a-z0-9-/]*/.test(
        to.path
      ) &&
      to.params.creativeSlug
    ) {
      const clientCreativeSlug = `${to.params.clientSlug}/${to.params.creativeSlug}`;
      const pState = useStorage(
        'client-creative-stage-paths',
        {} as {
          [clientCreativeSlug: string]: {
            [stage: string]: { path: string; expire: Date };
          };
        }
      );
      const stage = to.path.split('/')[5];
      const rest = to.path.split('/').slice(6).join('/');
      if (!pState.value[clientCreativeSlug]) {
        pState.value[clientCreativeSlug] = {};
      }
      pState.value[clientCreativeSlug][stage] = {
        path: rest,
        expire: new Date(Date.now() + 1000 * 60 * 5),
      };
    }
  } else if (to.path.startsWith('/agency/')) {
    const state = useStorage(
      'agency-path',
      {} as { path: string; expire: Date }
    );
    state.value = {
      path: to.path,
      expire: new Date(Date.now() + 1000 * 60 * 5), // 5 minutes
    };
  }
});
