import revive_payload_client_As8fGVzOMb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@9.13.0_rollup@4.24.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_18wTwj6fNH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@9.13.0_rollup@4.24.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ccTzdHjZwD from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@9.13.0_rollup@4.24.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_tzmH66vnPZ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_WZfUl32nEp from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@9.13.0_rollup@4.24.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jagbYg3dtF from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@9.13.0_rollup@4.24.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uD88CYFN3W from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@9.13.0_rollup@4.24.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_isg6yxJb3w from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@9.13.0_rollup@4.24.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_5ewgSyygSh from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.2_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_LbDWElWdUj from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@9.13.0_rollup@4.24.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import slideovers_FVtfPHKdrK from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_jwt-decode@3.1.2_rollup@4.24.2_ts-node@10.9.2_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_Ir1j6pddFN from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_jwt-decode@3.1.2_rollup@4.24.2_ts-node@10.9.2_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ikBB5Z5lbW from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_jwt-decode@3.1.2_rollup@4.24.2_ts-node@10.9.2_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_slJAe1Dr9q from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@4.24.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_akCWfekHDN from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.6.1_rollup@4.24.2_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_tMSMNBDaQe from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.2_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_DJrO37ytXc from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.2_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import auth_redirect_7YxV930Bgu from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import sentry_client_fQcJyPDwQk from "/opt/build/repo/node_modules/.pnpm/@sentry+nuxt@8.35.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.27.0_@opentelemetry+instru_xu3nkovzhyzwzuvlz7m2bq6rjq/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import chartjs_client_N7GULTnRpX from "/opt/build/repo/plugins/chartjs.client.ts";
import vue_draggable_client_CtEMRI3Db3 from "/opt/build/repo/plugins/vue-draggable.client.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_As8fGVzOMb,
  unhead_18wTwj6fNH,
  router_ccTzdHjZwD,
  supabase_client_tzmH66vnPZ,
  payload_client_WZfUl32nEp,
  navigation_repaint_client_jagbYg3dtF,
  check_outdated_build_client_uD88CYFN3W,
  chunk_reload_client_isg6yxJb3w,
  plugin_vue3_5ewgSyygSh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LbDWElWdUj,
  formkitPlugin_pZqjah0RUG,
  slideovers_FVtfPHKdrK,
  modals_Ir1j6pddFN,
  colors_ikBB5Z5lbW,
  plugin_client_slJAe1Dr9q,
  plugin_akCWfekHDN,
  switch_locale_path_ssr_tMSMNBDaQe,
  i18n_DJrO37ytXc,
  auth_redirect_7YxV930Bgu,
  sentry_client_fQcJyPDwQk,
  sentry_client_config_o34nk2sJbg,
  chartjs_client_N7GULTnRpX,
  vue_draggable_client_CtEMRI3Db3,
  vue_query_wrmMkNpEpe
]