<script setup lang="ts">
const nuxtApp = useNuxtApp();
const toast = useToast();

const firedUpdateToast = ref(false);
nuxtApp.hook('app:manifest:update', () => {
  if (firedUpdateToast.value) {
    return;
  }
  firedUpdateToast.value = true;
  toast.add({
    title:
      'There is a new version of the app available. Please, refresh the page for optimal experience.',
    actions: [
      {
        label: 'Refresh',
        click: () => {
          window.location.reload();
        },
      },
    ],
    timeout: 0,
  });
});
</script>
<template>
  <div class="w-full min-h-screen flex flex-col items-center">
    <NuxtPage />

    <UNotifications
      :ui="{
        background: 'bg-ht-primary-bg-fill dark:bg-ht-primary-bg-fill',
        ring: 'ring-ht-border-2 dark:ring-ht-border-2',
        progress: {
          background: 'bg-ht-monochrome-bd dark:bg-ht-monochrome-bd',
        },
      }"
      class="w-[400px]"
    />
  </div>
</template>
