import type { DefaultConfigOptions } from '@formkit/vue';
import { createProPlugin, inputs } from '@formkit/pro';
import { generateClasses } from '@formkit/themes';
import type { FormKitNode } from '@formkit/core';

const focusPlugin = (node: FormKitNode) => {
  if (node.type === 'input') {
    const focus = () => {
      const el = document.getElementById(node.props.id!);
      if (el) {
        el.focus();
      }
    };
    node.context!.fns.focus = focus;
  }
};

const blurPlugin = (node: FormKitNode) => {
  if (node.type === 'input') {
    const blur = () => {
      const el = document.getElementById(node.props.id!);
      if (el) {
        el.blur();
      }
    };
    node.context!.fns.blur = blur;
  }
};

const config: DefaultConfigOptions = {
  config: {
    classes: generateClasses({
      global: {
        outer: 'h-outer',
      },

      'family:text': {
        outer: 'h-outer',
        label: 'h-label',
        inner: 'h-inner text-f',
        input: 'h-input text-f placeholder-ht-text-gray-1',
        help: 'h-help',
        messages: 'h-messages',
        message: 'h-message',
        suffixIcon: 'h-suffixIcon',
      },

      textarea: {
        outer: 'h-outer',
        label: 'h-label',
        inner: 'h-inner textarea',
        input: 'h-input textarea placeholder-ht-text-gray-1',
        help: 'h-help',
        messages: 'h-messages',
        message: 'h-message',
        suffixIcon: 'h-suffixIcon',
      },
    }),
  },

  // @ts-ignore
  plugins: [createProPlugin('fk-7329fe8a6f', inputs), focusPlugin, blurPlugin],
};

export default config;
