import { default as budget_45labelsrNH4Si0MTfMeta } from "/opt/build/repo/pages/agency/budget-labels.vue?macro=true";
import { default as clients2ovjpDiJkUMeta } from "/opt/build/repo/pages/agency/clients.vue?macro=true";
import { default as memberspKLJa1cx8CMeta } from "/opt/build/repo/pages/agency/members.vue?macro=true";
import { default as overviewKozjCkMEPYMeta } from "/opt/build/repo/pages/agency/overview.vue?macro=true";
import { default as settingsNm4pstANt4Meta } from "/opt/build/repo/pages/agency/settings.vue?macro=true";
import { default as tags5hv9EYSOwgMeta } from "/opt/build/repo/pages/agency/tags.vue?macro=true";
import { default as agencyoaqhChQ4eBMeta } from "/opt/build/repo/pages/agency.vue?macro=true";
import { default as _91stage_93qN3sXHc2GVMeta } from "/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/[stage].vue?macro=true";
import { default as outletsXrbMD1DEwmMeta } from "/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/pre-production/outlets.vue?macro=true";
import { default as scriptpadlt3b9IYG5pMeta } from "/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/pre-production/scriptpad.vue?macro=true";
import { default as talents4LyXDiMsv5Meta } from "/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/pre-production/talents.vue?macro=true";
import { default as preview12CkKOm6fuMeta } from "/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/production/preview.vue?macro=true";
import { default as analytics767ouGTvptMeta } from "/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/publishing/analytics.vue?macro=true";
import { default as publishXXjlpcCtN3Meta } from "/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/publishing/publish.vue?macro=true";
import { default as _91creativeSlug_93tRW2e0vEcWMeta } from "/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug].vue?macro=true";
import { default as creativesEYl55QqCC2Meta } from "/opt/build/repo/pages/client/[clientSlug]/creatives.vue?macro=true";
import { default as overviewUPIKrhI9ElMeta } from "/opt/build/repo/pages/client/[clientSlug]/overview.vue?macro=true";
import { default as _91stage_93RPqi46UzTxMeta } from "/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/[stage].vue?macro=true";
import { default as brainstormingCNZreqLkBMMeta } from "/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/execution/brainstorming.vue?macro=true";
import { default as creativespDDDGBlaR0Meta } from "/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/execution/creatives.vue?macro=true";
import { default as audienceSR6bAkTeYpMeta } from "/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/planning/audience.vue?macro=true";
import { default as budgetewFVL8hm1UMeta } from "/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/planning/budget.vue?macro=true";
import { default as coaLci87setZ5Meta } from "/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/planning/coa.vue?macro=true";
import { default as outletsuDydEagJMqMeta } from "/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/planning/outlets.vue?macro=true";
import { default as talents8REHUWGTAdMeta } from "/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/planning/talents.vue?macro=true";
import { default as financialsqcugtYDop4Meta } from "/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/reporting/financials.vue?macro=true";
import { default as results_45per_45platformMAeJxDBIVFMeta } from "/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/reporting/results-per-platform.vue?macro=true";
import { default as _91projectSlug_93tcea1KcdjfMeta } from "/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug].vue?macro=true";
import { default as projectssc3NvXoiDmMeta } from "/opt/build/repo/pages/client/[clientSlug]/projects.vue?macro=true";
import { default as collaboratorso1U0b8scdhMeta } from "/opt/build/repo/pages/client/[clientSlug]/settings/collaborators.vue?macro=true";
import { default as generalDlLqSwEx0HMeta } from "/opt/build/repo/pages/client/[clientSlug]/settings/general.vue?macro=true";
import { default as tagsqRQEfGiHfiMeta } from "/opt/build/repo/pages/client/[clientSlug]/settings/tags.vue?macro=true";
import { default as settingsVT5YDGVdWVMeta } from "/opt/build/repo/pages/client/[clientSlug]/settings.vue?macro=true";
import { default as _91clientSlug_9383wUatAnlkMeta } from "/opt/build/repo/pages/client/[clientSlug].vue?macro=true";
import { default as dashboardB0S6bZ6pqpMeta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as index3PjcUXEBlvMeta } from "/opt/build/repo/pages/discover/index.vue?macro=true";
import { default as _91shortlistSlug_93pCsp4pkpRaMeta } from "/opt/build/repo/pages/discover/shortlist/[shortlistSlug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as confirmQDoUDDR3MEMeta } from "/opt/build/repo/pages/login/confirm.vue?macro=true";
import { default as index9WruYoq02MMeta } from "/opt/build/repo/pages/login/index.vue?macro=true";
import { default as privacyjCuzG6sq6pMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
import { default as supportwDOY01gK8yMeta } from "/opt/build/repo/pages/support.vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
export default [
  {
    name: "agency",
    path: "/agency",
    meta: agencyoaqhChQ4eBMeta || {},
    redirect: agencyoaqhChQ4eBMeta?.redirect,
    component: () => import("/opt/build/repo/pages/agency.vue"),
    children: [
  {
    name: "agency-budget-labels",
    path: "budget-labels",
    component: () => import("/opt/build/repo/pages/agency/budget-labels.vue")
  },
  {
    name: "agency-clients",
    path: "clients",
    component: () => import("/opt/build/repo/pages/agency/clients.vue")
  },
  {
    name: "agency-members",
    path: "members",
    component: () => import("/opt/build/repo/pages/agency/members.vue")
  },
  {
    name: "agency-overview",
    path: "overview",
    component: () => import("/opt/build/repo/pages/agency/overview.vue")
  },
  {
    name: "agency-settings",
    path: "settings",
    component: () => import("/opt/build/repo/pages/agency/settings.vue")
  },
  {
    name: "agency-tags",
    path: "tags",
    component: () => import("/opt/build/repo/pages/agency/tags.vue")
  }
]
  },
  {
    name: "client-clientSlug",
    path: "/client/:clientSlug()",
    meta: _91clientSlug_9383wUatAnlkMeta || {},
    redirect: _91clientSlug_9383wUatAnlkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/client/[clientSlug].vue"),
    children: [
  {
    name: "client-clientSlug-creative-creativeSlug",
    path: "creative/:creativeSlug()",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug].vue"),
    children: [
  {
    name: "client-clientSlug-creative-creativeSlug-stage",
    path: ":stage()",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/[stage].vue")
  },
  {
    name: "client-clientSlug-creative-creativeSlug-pre-production-outlets",
    path: "pre-production/outlets",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/pre-production/outlets.vue")
  },
  {
    name: "client-clientSlug-creative-creativeSlug-pre-production-scriptpad",
    path: "pre-production/scriptpad",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/pre-production/scriptpad.vue")
  },
  {
    name: "client-clientSlug-creative-creativeSlug-pre-production-talents",
    path: "pre-production/talents",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/pre-production/talents.vue")
  },
  {
    name: "client-clientSlug-creative-creativeSlug-production-preview",
    path: "production/preview",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/production/preview.vue")
  },
  {
    name: "client-clientSlug-creative-creativeSlug-publishing-analytics",
    path: "publishing/analytics",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/publishing/analytics.vue")
  },
  {
    name: "client-clientSlug-creative-creativeSlug-publishing-publish",
    path: "publishing/publish",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/creative/[creativeSlug]/publishing/publish.vue")
  }
]
  },
  {
    name: "client-clientSlug-creatives",
    path: "creatives",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/creatives.vue")
  },
  {
    name: "client-clientSlug-overview",
    path: "overview",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/overview.vue")
  },
  {
    name: "client-clientSlug-project-projectSlug",
    path: "project/:projectSlug()",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug].vue"),
    children: [
  {
    name: "client-clientSlug-project-projectSlug-stage",
    path: ":stage()",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/[stage].vue")
  },
  {
    name: "client-clientSlug-project-projectSlug-execution-brainstorming",
    path: "execution/brainstorming",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/execution/brainstorming.vue")
  },
  {
    name: "client-clientSlug-project-projectSlug-execution-creatives",
    path: "execution/creatives",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/execution/creatives.vue")
  },
  {
    name: "client-clientSlug-project-projectSlug-planning-audience",
    path: "planning/audience",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/planning/audience.vue")
  },
  {
    name: "client-clientSlug-project-projectSlug-planning-budget",
    path: "planning/budget",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/planning/budget.vue")
  },
  {
    name: "client-clientSlug-project-projectSlug-planning-coa",
    path: "planning/coa",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/planning/coa.vue")
  },
  {
    name: "client-clientSlug-project-projectSlug-planning-outlets",
    path: "planning/outlets",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/planning/outlets.vue")
  },
  {
    name: "client-clientSlug-project-projectSlug-planning-talents",
    path: "planning/talents",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/planning/talents.vue")
  },
  {
    name: "client-clientSlug-project-projectSlug-reporting-financials",
    path: "reporting/financials",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/reporting/financials.vue")
  },
  {
    name: "client-clientSlug-project-projectSlug-reporting-results-per-platform",
    path: "reporting/results-per-platform",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/project/[projectSlug]/reporting/results-per-platform.vue")
  }
]
  },
  {
    name: "client-clientSlug-projects",
    path: "projects",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/projects.vue")
  },
  {
    name: "client-clientSlug-settings",
    path: "settings",
    meta: settingsVT5YDGVdWVMeta || {},
    redirect: settingsVT5YDGVdWVMeta?.redirect,
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/settings.vue"),
    children: [
  {
    name: "client-clientSlug-settings-collaborators",
    path: "collaborators",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/settings/collaborators.vue")
  },
  {
    name: "client-clientSlug-settings-general",
    path: "general",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/settings/general.vue")
  },
  {
    name: "client-clientSlug-settings-tags",
    path: "tags",
    component: () => import("/opt/build/repo/pages/client/[clientSlug]/settings/tags.vue")
  }
]
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/opt/build/repo/pages/dashboard.vue")
  },
  {
    name: "discover",
    path: "/discover",
    component: () => import("/opt/build/repo/pages/discover/index.vue")
  },
  {
    name: "discover-shortlist-shortlistSlug",
    path: "/discover/shortlist/:shortlistSlug()",
    component: () => import("/opt/build/repo/pages/discover/shortlist/[shortlistSlug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "login-confirm",
    path: "/login/confirm",
    component: () => import("/opt/build/repo/pages/login/confirm.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/build/repo/pages/login/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/build/repo/pages/privacy.vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/opt/build/repo/pages/support.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/build/repo/pages/terms.vue")
  }
]